var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Done" }, [
      _c("div", { staticClass: "mainBox" }, [
        _c("div", { staticClass: "form join done" }, [
          _vm._m(0),
          _vm._m(1),
          _c("div", { staticClass: "btnWrap" }, [
            _c(
              "button",
              { staticClass: "btn full primary", on: { click: _vm.submit } },
              [_vm._v("로그인")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logoBox" }, [
      _c("a", { staticClass: "logo" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/reviewmind/logo2.png"),
            alt: "리뷰마인드 홈"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "centerTxt" }, [
      _c("h2", [_vm._v("환영합니다"), _c("span", [_vm._v("!")])]),
      _vm._v(" 회원가입이 완료되었습니다. "),
      _c("br"),
      _vm._v("리뷰마인드 회원이 되신것을 진심으로 환영합니다. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }